<template>
    <div>
        <md-app>
            <md-app-toolbar class="md-primary" v-if="!mobile">
                <router-link :to="{name: 'dashboard.premium'}">
<!--                    <img src="https://rehab-connect.s3.amazonaws.com/assets/logo_home.svg" alt="Rehab.com Logo" class="rehab-logo">-->
                    <img src="https://rehab-connect.s3.amazonaws.com/assets/logo_home.svg" alt="Rehab.com Logo" class="rehab-logo">
                </router-link>
            </md-app-toolbar>

            <md-app-content v-if="funnelData">
                <router-link :to="'/premium/funnel/price-locked-in'" v-if="!mobile">
                    <div class="backArrow" to = ""><md-icon>keyboard_arrow_left</md-icon></div>
                </router-link>
                <div class="md-layout md-alignment-top-center">
                    <div class="md-layout-item ">
                        <div class="headers">
                            <h1 v-if="!mobile">Select a goal that is right for your listing</h1>
                            <h1 v-else>Select a goal for your listing</h1>
                            <h2 v-if="!mobile">What actions do you most want users to take?</h2>
                            </div>

                        <div class="md-layout md-alignment-top-center" v-if="!mobile">
                            <div class="md-xlarge-size-60 md-layout-item md-large-size-60 md-medium-size-60">
                                <div class="md-layout md-gutter">
                                    <div class="">
                                        <!-- UNLIMITED BED REQUESTS -->
                                        <div
                                                class="section bed-request-section"
                                                :class="{'selected': funnelData.goal === 'bedRequests'}"
                                                @click="funnelData.goal = 'bedRequests'"
                                                @mouseover="bedsHover = true"
                                                @mouseout="bedsHover = false"
                                        >

                                                      <div class="md-layout-item header button-container"><div class="copy">Unlimited Forms</div></div>

                                            <transition name="fade">
                                                <div
                                                        v-show="bedsHover || funnelData.goal === 'bedRequests'"
                                                        class="ribbon ribbon-small ribbon-orange">
                                                    <div class="banner">
                                                        <div class="text">Included</div>
                                                    </div>
                                                </div>
                                            </transition>


                                        </div>
                                    
                                        <!-- PREMIUM CLICKS -->
                                        <div
                                                class="section"
                                                :class="{'selected': funnelData.goal === 'clicks'}"
                                                @click="funnelData.goal='clicks'"
                                        >
                                            <div class="md-layout-item header"><md-icon>add</md-icon> Premium Clicks</div>

                                        </div>

                                        <!-- PREMIUM CALLS -->
                                        <div
                                                class="section"
                                                :class="{'selected': funnelData.goal === 'calls'}"
                                                @click="funnelData.goal='calls'"
                                        >
                                            <div class="md-layout-item header"><md-icon>add</md-icon> Premium Calls</div>

                                        </div>
                                        <!-- PREMIUM CALLS AND CLICKS -->
                                        <div
                                                class="section last"
                                                :class="{'selected': funnelData.goal === 'callsAndClicks'}"
                                                @click="funnelData.goal='callsAndClicks'"
                                        >
                                            <div class="md-layout-item header"><md-icon>add</md-icon> Premium Calls and Clicks</div>

                                        </div>
                                    </div>
                                    <div class="md-layout-item description">

                                        <!-- UNLIMITED BED REQUESTS -->
                                        <div class="description-body" v-if="funnelData.goal === 'bedRequests'">
                                            <h3 class="choose">
                                                Choose this goal to:
                                            </h3>
                                            <ul>
                                                <li>Replace third-party ads</li>
                                                <li>Generate more leads</li>
                                            </ul>

                                            <hr>

                                            <div class="pricing included-price">
                                                <div class="subscription-price">
                                                    <template v-if="subscriptionCostsOverridesByFacility(funnelData.funnelFacility) && subscriptionCostsOverridesByFacility(funnelData.funnelFacility)[0]">
                                                        <span class="price-text">${{ subscriptionCostsOverridesByFacility(funnelData.funnelFacility)[0].overrides }}/month +</span></template>
                                                    <template v-else><span class="price-text">${{ subscriptioncosts.overrides }}/month +</span></template>
                                                </div>
                                                <div class="click-price ">
                                                    <span class="price-text">Included</span>
                                                </div>
                                                <p class="subtitle">Unlimited Forms</p>
                                            </div>

                                        </div>


                                        <!-- ADD PREMIUM CALLS -->
                                        <div class="description-body" v-else-if="funnelData.goal === 'calls'">
                                            <h3 class="choose">
                                                Choose this goal to:
                                            </h3>
                                            <ul>
                                                <li>Replace third-party ads</li>
                                                <li>Generate more calls</li>
                                            </ul>

                                            <hr>

                                            <div class="pricing">
                                                <div class="subscription-price">
                                                    <template v-if="subscriptionCostsOverridesByFacility(funnelData.funnelFacility) && subscriptionCostsOverridesByFacility(funnelData.funnelFacility)[0]">
                                                        <span class="price-text">${{ subscriptionCostsOverridesByFacility(funnelData.funnelFacility)[0].overrides }}/month +</span></template>
                                                    <template v-else><span class="price-text">${{ subscriptioncosts.overrides }}/month +</span></template>
                                                </div>
                                                <div class="click-price">
                                                    <span class="price-text"><sup>$</sup>{{goPremiumFunnelInfo.facilityPricing.singleCallCost | formatClickPrice}}</span>
                                                    <!-- CALL PRICING TOOLTIP -->
                                                    <div
                                                            @mouseover="showCallTooltip = true"
                                                            @mouseout="showCallTooltip = false"
                                                            class="iconContainer"
                                                    >
                                                        <md-icon class="icon">help_outline</md-icon>
                                                    </div>
                                                    <div
                                                            class="tooltipbox number"
                                                            v-show="showCallTooltip"
                                                    >
                                                        We are committed to deliver quality calls. In an effort to do so, we have implemented a 30-second call buffer to ensure you are only paying for calls that matter. In addition, we searched public databases to gather as much data as possible to offer you the best price.
                                                        
                                                    </div>
                                                    <span class="per">per call</span>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- ADD PREMIUM CLICKS -->
                                        <div class="description-body" v-else-if="funnelData.goal === 'clicks'">
                                            <h3 class="choose">
                                                Choose this goal to:
                                            </h3>
                                            <ul>
                                                <li>Replace third-party ads</li>
                                                <li>Generate more visitors to your website</li>
                                            </ul>

                                            <hr>

                                            <div class="pricing">
                                                <div class="subscription-price">
                                                    <template v-if="subscriptionCostsOverridesByFacility(funnelData.funnelFacility) && subscriptionCostsOverridesByFacility(funnelData.funnelFacility)[0]">
                                                        <span class="price-text">${{ subscriptionCostsOverridesByFacility(funnelData.funnelFacility)[0].overrides }}/month +</span></template>
                                                    <template v-else><span class="price-text">${{ subscriptioncosts.overrides }}/month +</span></template>
                                                </div>
                                                <div class="click-price">
                                                    <span class="price-text"><sup>$</sup>{{goPremiumFunnelInfo.facilityPricing.singleClickCost | formatClickPrice}}</span>
                                                    <!-- CLICK PRICING TOOLTIP -->
                                                    <div
                                                            @mouseover="showClickTooltip = true"
                                                            @mouseout="showClickTooltip = false"
                                                            class="iconContainer"
                                                    >
                                                        <md-icon class="icon">help_outline</md-icon>
                                                    </div>
                                                    <div
                                                            class="tooltipbox number"
                                                            v-show="showClickTooltip"
                                                    >
                                                        Market pricing matters which is why we searched public databases to gather as much data as possible. Using facility name, search data and pricing data, we are able to offer you the best possible price per click.
                                                    </div>
                                                    <span class="per">per click</span>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- ADD PREMIUM CLICKS AND CALLS -->
                                        <div class="description-body" v-else-if="funnelData.goal === 'callsAndClicks'">
                                            <h3 class="choose">
                                                Choose this goal to:
                                            </h3>
                                            <ul>
                                                <li>Replace third-party ads</li>
                                                <li>Generate more clicks and calls</li>
                                            </ul>

                                            <hr>

                                            <div class="pricing">
                                                <div class="subscription-price">
                                                    <template v-if="subscriptionCostsOverridesByFacility(funnelData.funnelFacility) && subscriptionCostsOverridesByFacility(funnelData.funnelFacility)[0]">
                                                        <span class="price-text">${{ subscriptionCostsOverridesByFacility(funnelData.funnelFacility)[0].overrides }}/month +</span></template>
                                                    <template v-else><span class="price-text">${{ subscriptioncosts.overrides }}/month +</span></template>
                                                </div>

                                                <!-- CALL PRICING -->
                                                <div class="click-price">
                                                    <span class="price-text"><sup>$</sup>{{goPremiumFunnelInfo.facilityPricing.singleCallCost | formatClickPrice}}</span>
                                                    <!-- CALL PRICING TOOLTIP -->
                                                    <div
                                                            @mouseover="showCallTooltip = true"
                                                            @mouseout="showCallTooltip = false"
                                                            class="iconContainer"
                                                    >
                                                        <md-icon class="icon">help_outline</md-icon>
                                                    </div>
                                                    <div
                                                            class="tooltipbox number"
                                                            v-show="showCallTooltip"
                                                    >
                                                        We are committed to deliver quality calls. In an effort to do so, we have implemented a 30-second call buffer to ensure you are only paying for calls that matter. In addition, we searched public databases to gather as much data as possible to offer you the best price.
                                                    </div>
                                                    <span class="per">per call</span>
                                                </div>



                                                  <div class="click-price call-price">
                                                    <!-- CLICK PRICING -->
                                                    <span class="price-text"><sup>$</sup>{{goPremiumFunnelInfo.facilityPricing.singleClickCost | formatClickPrice}}</span>
                                                    <!-- CLICK PRICING TOOLTIP -->
                                                    <div
                                                        @mouseover="showClickTooltip = true"
                                                        @mouseout="showClickTooltip = false"
                                                        class="iconContainer"
                                                    >
                                                        <md-icon class="icon">help_outline</md-icon>
                                                    </div>
                                                    <div
                                                            class="tooltipbox number"
                                                            v-show="showClickTooltip"
                                                    >
                                                        Market pricing matters which is why we searched public databases to gather as much data as possible. Using your brand name, search data, pricing data and we are able to offer you the best possible price per click.
                                                    </div>
                                                    <span class="per">per click</span>
                                                </div>
                                                <div class="plus"></div>
                                            </div>
                                        </div>

                                        <md-button
                                                :md-ripple="false"
                                                class="md-primary md-raised"
                                                @click="selectGoal"
                                                :disabled="!funnelData.goal"
                                        >Select Goal</md-button>
                                    </div>


                                </div>
                            </div>

                        </div>
                        <template v-if="mobile">

                            <div class="accordion">
                                <!-- Looping of the props. -->
                                <div class="accordion-item unlimited">
                                    <div class="accordion-header">
                                        <!-- The click event of each accordion menu -->
                                        <a href="#" v-on:click="expand('0')">
                                            <div v-bind:ref="'accordion-header-0'" class="accordion-header-div unlimited-leads">Unlimited Forms</div>
                                            
                                             <transition name="fade">
                                                <div
                                                       
                                                        class="ribbon ribbon-small ribbon-orange">
                                                    <div class="banner">
                                                        <div class="text">Included</div>
                                                    </div>
                                                </div>
                                            </transition>
                                        </a>
                                    </div>
                                    <!-- We need the ref to get the DOM of the body. -->
                                    <div class="accordion-body" v-bind:ref="'accordion-body-0'">
                                         <div class="description-body accordion-content">
                                            <h3 class="choose">
                                                Choose this goal to:
                                            </h3>
                                            <ul>
                                                <li>Replace third-party ads</li>
                                                <li>Generate more leads</li>
                                            </ul>

                                            <hr>

                                            <div class="pricing included-price">
                                                <div class="subscription-price">
                                                    <template v-if="subscriptionCostsOverridesByFacility(funnelData.funnelFacility) && subscriptionCostsOverridesByFacility(funnelData.funnelFacility)[0]">
                                                        <span class="price-text">${{ subscriptionCostsOverridesByFacility(funnelData.funnelFacility)[0].overrides }}/month +</span></template>
                                                    <template v-else><span class="price-text">${{ subscriptioncosts.overrides }}/month +</span></template>
                                                </div>
                                                <div class="click-leads">
                                                    <span class="price-text">Included</span>
                                                </div>
                                                <p class="subtitle">Unlimited Forms</p>
                                                  <md-button
                                                :md-ripple="false"
                                                class="md-primary md-raised"
                                                @click="selectLeads"
                                                :disabled="!funnelData.goal"
                                        >Select Goal</md-button>
                                            </div>

                                        </div>
                                      
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-header">
                                        <!-- The click event of each accordion menu -->
                                        <a href="#" v-on:click="expand(1)">
                                            <div v-bind:ref="'accordion-header-1'" class="accordion-header-div premium-header">+ Premium Clicks</div>
                                            <div class="accordion-header-div">
                                            <div class="accordion-caret"></div>
                                            </div>
                                        </a>
                                    </div>
                                    <!-- We need the ref to get the DOM of the body. -->
                                    <div class="accordion-body" v-bind:ref="'accordion-body-1'">
                                         <div class="description-body accordion-content">
                                            <h3 class="choose">
                                                Choose this goal to:
                                            </h3>
                                            <ul>
                                                <li>Replace third-party ads</li>
                                                <li>Generate more visitors to your website</li>
                                            </ul>

                                            <hr>

                                            <div class="pricing">
                                                <div class="subscription-price">
                                                    <template v-if="subscriptionCostsOverridesByFacility(funnelData.funnelFacility) && subscriptionCostsOverridesByFacility(funnelData.funnelFacility)[0]">
                                                        <span class="price-text">${{ subscriptionCostsOverridesByFacility(funnelData.funnelFacility)[0].overrides }}/month +</span></template>
                                                    <template v-else><span class="price-text">${{ subscriptioncosts.overrides }}/month +</span></template>
                                                </div>
                                                <div class="click-price">
                                                    <span class="price-text"><sup>$</sup>{{goPremiumFunnelInfo.facilityPricing.singleClickCost | formatClickPrice}}</span>
                                                    <!-- CLICK PRICING TOOLTIP -->
                                                    <div
                                                            @mouseover="showClickTooltip = true"
                                                            @mouseout="showClickTooltip = false"
                                                            class="iconContainer"
                                                    >
                                                        <md-icon class="icon">help_outline</md-icon>
                                                    </div>
                                                   
                                                   <span class="per">per click</span>
                                                </div>
                                            </div> 
                                             <div
                                                    class="tooltipbox number"
                                                    @click="showClickTooltip = false"
                                                    v-show="showClickTooltip"
                                            >
                                                Market pricing matters which is why we searched public databases to gather as much data as possible. Using facility name, search data and pricing data, we are able to offer you the best possible price per click.
                                                <md-icon>close</md-icon>
                                            </div>
                                            <md-button
                                                :md-ripple="false"
                                                class="md-primary md-raised"
                                                @click="selectClicksCalls('clicks')"
                                                :disabled="!funnelData.goal"
                                            >Select Goal</md-button>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-header">
                                        <!-- The click event of each accordion menu -->
                                        <a href="#" v-on:click="expand(2)">
                                            <div v-bind:ref="'accordion-header-2'" class="accordion-header-div premium-header">+ Premium Calls</div>
                                            <div class="accordion-header-div">
                                            <div class="accordion-caret"></div>
                                            </div>
                                        </a>
                                    </div>
                                    <!-- We need the ref to get the DOM of the body. -->
                                    <div class="accordion-body" v-bind:ref="'accordion-body-2'">
                                        <div class="description-body accordion-content">
                                            <h3 class="choose">
                                                Choose this goal to:
                                            </h3>
                                            <ul>
                                                <li>Replace third-party ads</li>
                                                <li>Generate more calls</li>
                                            </ul>

                                            <hr>

                                            <div class="pricing">
                                                <div class="subscription-price">
                                                    <template v-if="subscriptionCostsOverridesByFacility(funnelData.funnelFacility) && subscriptionCostsOverridesByFacility(funnelData.funnelFacility)[0]">
                                                        <span class="price-text">${{ subscriptionCostsOverridesByFacility(funnelData.funnelFacility)[0].overrides }}/month +</span></template>
                                                    <template v-else><span class="price-text">${{ subscriptioncosts.overrides }}/month +</span></template>
                                                </div>
                                                <div class="click-price">
                                                    <span class="price-text"><sup>$</sup>{{goPremiumFunnelInfo.facilityPricing.singleCallCost | formatClickPrice}}</span>
                                                    <!-- CALL PRICING TOOLTIP -->
                                                    <div
                                                            @mouseover="showCallTooltip = true"
                                                            @mouseout="showCallTooltip = false"
                                                            class="iconContainer"
                                                    >
                                                        <md-icon class="icon">help_outline</md-icon>
                                                    </div>
                                                   
                                                   <span class="per">per call</span>
                                                </div>
                                            </div>
                                            <div
                                                    class="tooltipbox number"
                                                    @click="showCallTooltip = false"
                                                    v-show="showCallTooltip"
                                            >
                                                We are committed to deliver quality calls. In an effort to do so, we have implemented a 30-second call buffer to ensure you are only paying for calls that matter. 
                                                <md-icon>close</md-icon>
                                            </div>
                                            <md-button
                                                :md-ripple="false"
                                                class="md-primary md-raised"
                                                @click="selectClicksCalls('calls')"
                                                :disabled="!funnelData.goal"
                                            >Select Goal</md-button>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-header">
                                        <!-- The click event of each accordion menu -->
                                        <a href="#" v-on:click="expand(3)">
                                            <div v-bind:ref="'accordion-header-3'" class="accordion-header-div premium-header">+ Premium Calls and Clicks</div>
                                            <div class="accordion-header-div">
                                            <div class="accordion-caret"></div>
                                            </div>
                                        </a>
                                    </div>
                                    <!-- We need the ref to get the DOM of the body. -->
                                    <div class="accordion-body" v-bind:ref="'accordion-body-3'">
                                        <div class="description-body accordion-content">
                                            <h3 class="choose">
                                                Choose this goal to:
                                            </h3>
                                            <ul>
                                                <li>Replace third-party ads</li>
                                                <li>Generate more clicks and calls</li>
                                            </ul>

                                            <hr>

                                            <div class="pricing">
                                                <div class="subscription-price">
                                                    <template v-if="subscriptionCostsOverridesByFacility(funnelData.funnelFacility) && subscriptionCostsOverridesByFacility(funnelData.funnelFacility)[0]">
                                                        <span class="price-text">${{ subscriptionCostsOverridesByFacility(funnelData.funnelFacility)[0].overrides }}/month +</span></template>
                                                    <template v-else><span class="price-text">${{ subscriptioncosts.overrides }}/month +</span></template>
                                                </div>

                                                <!-- CALL PRICING -->
                                                <div class="click-price">
                                                    <span class="price-text"><sup>$</sup>{{goPremiumFunnelInfo.facilityPricing.singleCallCost | formatClickPrice}}</span>
                                                    <!-- CALL PRICING TOOLTIP -->
                                                    <div
                                                            @mouseover="showCallTooltip = true"
                                                            @mouseout="showCallTooltip = false"
                                                            class="iconContainer"
                                                    >
                                                        <md-icon class="icon">help_outline</md-icon>
                                                    </div>
                                                  
                                                    <span class="per">per call</span>
                                                </div>

                                                <div class="click-price call-price">
                                                    <!-- CLICK PRICING -->
                                                    <span class="price-text"><sup>$</sup>{{goPremiumFunnelInfo.facilityPricing.singleClickCost | formatClickPrice}}</span>
                                                    <!-- CLICK PRICING TOOLTIP -->
                                                    <div
                                                        @mouseover="showClickTooltip = true"
                                                        @mouseout="showClickTooltip = false"
                                                        class="iconContainer"
                                                    >
                                                        <md-icon class="icon">help_outline</md-icon>
                                                    </div>
                                                   
                                                    <span class="per">per click</span>
                                                </div>
                                            </div> 
                                            <div
                                                    class="tooltipbox number"
                                                    @click="showCallTooltip = false"
                                                    v-show="showCallTooltip"
                                            >
                                                We are committed to deliver quality calls. In an effort to do so, we have implemented a 30-second call buffer to ensure you are only paying for calls that matter. 
                                                <md-icon>close</md-icon>
                                            </div>
                                            <div
                                                    class="tooltipbox number"
                                                    @click="showClickTooltip = false"
                                                    v-show="showClickTooltip"
                                            >
                                                Using your brand name, search data and pricing data, we are able to offer you the best possible price per click.
                                                <md-icon>close</md-icon>
                                            </div>
                                        <md-button
                                            :md-ripple="false"
                                            class="md-primary md-raised"
                                            @click="selectClicksCalls('callsAndClicks')"
                                            :disabled="!funnelData.goal"
                                        >Select Goal</md-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>

                </div>
            </md-app-content>
        </md-app>

    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'
    import { TweenLite, TimelineLite, Back, Elastic, Expo, Bounce } from "gsap"
    export default {
        name: "SelectGoal",
        data() {
            return {
                goal: null,
                funnelData: null,
                showCallTooltip: false,
                showClickTooltip: false,
                bedsHover: false,
                showBedRequestsOption: true,
                mobile: false,
                contents:[
                    {title: 'Unlimited Forms', description: 'hello', active:false},
                    {title: '+ Premium Clicks', description: 'hello', active:false},
                    {title: '+ Premium Calls', description: 'hello', active:false},
                    {title: '+ Premium Calls and Clicks', description: 'hello', active:false}]
            }
        },
        created() {
            if( this.windowWidth <= 760 ) {
                this.mobile = true
            }
            this.funnelData = JSON.parse(JSON.stringify(this.goPremiumFunnelInfo))
        },
        computed: {
            ...mapGetters([
                'goPremiumFunnelInfo',
                'totalSubscriptionCost',
                'facilities',
                'calculateBudget',
                'subscriptions',
                'facilitySubscriptionCost',
                'subscriptioncosts',
                'subscriptionCostsOverridesByFacility'
            ]),

            fiveBudget(){
                return this.calculateBudget({numInteractions: 5})
            },
        },
        methods: {
            expand: function(i) {

                let el = this.$refs['accordion-body-' + i];
                let elh = this.$refs['accordion-header-' + i];

                for (let index = 0; index < this.contents.length; ++index) {
                  
                    if(index != i){
                        this.contents[index].active = false;
                        let els = this.$refs['accordion-body-' + index];
                        let elh = this.$refs['accordion-header-' + index];
                        elh.style.background = "#D9EAF8";
                        elh.style.color = "#2B4055";
                        
                        TweenLite.to(els, 0.25, {
                            height: 0
                        });
                         setTimeout(() => { els.style.border = "0"; }, 300);
                    }
                }

                if (this.contents[i].active === false) {

                    if(i>=2)
                    {
                        window.scrollTo({
                            top: 700,
                            behavior: 'smooth'
                        });
                    }

                    this.contents[i].active = true;
                    el.style.border = "2px solid #eee";
                    elh.style.background = "#0078d3";
                    elh.style.color = "#fff";
                
                    TweenLite.to(el, 1, {
                        height: el.scrollHeight
                    });

                    if(i>=2)
                    {
                        setTimeout(function (){

                            window.scrollTo({
                                top: 700,
                                behavior: 'smooth'
                            });

                        }, 50);
                    }

                } else {
                    this.contents[i].active = false;
                    
                    elh.style.background = "#0078d3";
                    elh.style.color = "#fff";

                    TweenLite.to(el, 0.25, {
                        height: 0
                    });
                }
            },
            ...mapActions([
                'updateGoPremiumFunnelInfo',
                'hubspotGoPremiumSelectGoal',
                'fetchSubscriptionCosts'
            ]),
            selectGoal() {
                this.hubspotGoPremiumSelectGoal({goal: this.funnelData.goal});

                if(this.funnelData.goal === 'bedRequests') {
                    this.funnelData.isSubscriptionOnly = true;
                    this.funnelData.goal = null;
                    this.funnelData.budget = null;
                    this.funnelData.sliderAmount = 0;
                    this.updateGoPremiumFunnelInfo(this.funnelData);
                    this.$router.push({name: 'premium.funnel.checkout'})
                }else {
                    this.funnelData.isSubscriptionOnly = false;
                    this.updateGoPremiumFunnelInfo(this.funnelData);
                    this.$router.push({name: 'premium.funnel.budget'})
                }
            },
            selectLeads() {
                this.funnelData.goal = 'bedRequests';
                this.hubspotGoPremiumSelectGoal({goal: this.funnelData.goal});
                this.funnelData.isSubscriptionOnly = true;
                this.funnelData.goal = null;
                this.funnelData.budget = null;
                this.funnelData.sliderAmount = 0;
                this.updateGoPremiumFunnelInfo(this.funnelData);
                this.$router.push({name: 'premium.funnel.checkout'})
            },
            selectClicksCalls(option) {
                this.funnelData.goal = option;
                this.hubspotGoPremiumSelectGoal({goal: this.funnelData.goal});
                this.funnelData.isSubscriptionOnly = false;
                this.updateGoPremiumFunnelInfo(this.funnelData);
                this.$router.push({name: 'premium.funnel.budget'})
            },
        },
        filters: {
            formatClickPrice(price){
                if(price % 1 === 0){
                    return price
                } else {
                    return price.toFixed(2);
                }

            }
        },
        async mounted() {

            await this.fetchSubscriptionCosts();

            this.$watch('windowWidth', (value, oldValue) => {

                if(value <= 760){
                    this.mobile = true;
                }else{
                    this.mobile = false;
                }
            
            })
            const funnelData = JSON.parse(JSON.stringify(this.goPremiumFunnelInfo))
            // console.log('FUNNEL DATA IN SELECT GOAL', funnelData);
            if(!funnelData.goal){
                funnelData.goal = "bedRequests"
            }

            this.funnelData = funnelData;

            this.funnelData.currentStep = this.$route.name;
            this.updateGoPremiumFunnelInfo(this.funnelData);

            // OPEN Unlimited Forms ACCORDIAN ON MOBILE
            if(this.mobile){
                this.expand('0')
            }

        },
    }
</script>

<style lang="scss" scoped>
    .page-container, .md-app, .md-app-container, .md-app-scroller, .md-layout-column, .md-flex, .md-theme-default, .md-scrollbar, .md-content, .md-drawer, .md-theme-default, .md-tabs-content {
        background-color: #ffffff !important;
    }

    .choice-tabs {
        .md-tab {
            background-color: #fff;
        }
    }

    .md-toolbar {
        background-color: #FAFAFA !important;
    }

    .md-primary{
        border: 1px solid #eee
    }

    .rehab-logo {
        height: 32px;
        margin-left: 55px;
    }

    .headers {
        margin-bottom: 38px;
        text-align: center;

        h1{
            font-size: 36px;
            font-weight: 300;
            display: block;
            text-align: center;
            padding-top: 0;
            margin-top: 0;
        }

        h2 {
            font-weight: 300;
            font-size: 24px;
            line-height: 26px;
        }
    }

    /*.section-label {*/
    /*    color: #0B61FF;*/
    /*    font-size: 14px;*/
    /*    font-weight: 500;*/
    /*    margin-bottom: 6px;*/
    /*    margin-top: 0;*/
    /*}*/

    /*.bed-request-section{*/
    /*    border-bottom: 3px solid #9B9C9E;*/
    /*}*/

    

  
    .section {
        padding: 36px 25px 43px 25px;
        padding-right: 48px;
        height: 90px;
        /*background-color: #F4F7FB;*/
        cursor: pointer;
        color: #01193A;
        .button-container{
            display: inline-block;
            div{
                margin-top: 6px;
            }
        }

        .header {
            margin: auto;
            font-weight: 400;
            font-size: 18px;
            display: inline-block;
            vertical-align: middle;
            .md-icon {
                color: #545454;
                background-color: transparent !important;
            }
            
            margin-left: 30px;
            
            .included {
                border-radius: 10px;
                font-size: 12px;
                color: #ffffff;
                line-height: 14px;
                background-color: #0C76D3;
                padding: 4px 15px;
                margin-right: 5px;
                position: absolute;
                top: 10px;
                left: 60px;
            }
        }
    &.bed-request-section .header {
        margin-left: 51px;
        .included{
            float: left;
        }
        .copy{
            display: block;
            padding-top: 1px;
            float: left;
            padding-left: 4px;
        }
    }


        /*.summary {*/
        /*    padding: 15px 0 0 0;*/
        /*    font-size: 14px;*/
        /*}*/
    }

    .section:hover {
        background-color: #D9EBF9;
        transition-duration: .5s;
        border-radius: 5px;
    }

    .selected{
        .header{
            display: inline-block;
        }
    }

   
    .section.selected {
        /*border: 1px solid #0b61ff;*/
        background-color: #0078D3;
        color: #fff;
        border-radius: 5px;
        .md-icon {
            color: #fff;
            background-color: transparent !important;
        }
        .included {
            background-color: #fff;
            color: #0C76D3;
        }
    }

     .last{
        .header{
            display: block;
        }
    }


    .bed-request-section{
        position: relative;
        height: 95px;
        padding: 30px 25px 43px 25px;
    }

    .description {
        padding-top: 6px;
        position: relative;
        padding-left: 38px;
        .description-body {
            padding-left: 16px;

            h3 {
                font-size: 20px;
                color: #545454;
                margin-bottom: 0;
                margin-top: 25px;
                font-weight: 500;
            }
            .choose {
                margin-top: 30px;
            }
            p, li {
                color: #545454;
                font-size: 16px;
            }
            ul {
                list-style: none;
                padding-inline-start: 0;
                li {
                    line-height: 30px;
                    margin-left: 0;
                }
            }




            hr {
                display: block;
                height: 1px;
                border: 0;
                border-top: 1px solid #9F9F9F;
                margin: 25px 0 25px;
                padding: 0;
            }

            .pricing {
                text-align: left;
                /*padding-top: 25px;*/
                .subscription-price{
                    display: block;
                    margin-top: 20px;
                    margin-bottom: 20px;
                    font-size: 20px;
                    color: #545454;
                    font-weight: 400;
                }
                .call-price{
                     margin-left: 30px;
                }
                .click-price {
                    display: inline-block;
                    color: #545454;
                    font-weight: 500;
                   

                    sup {
                        font-size: 26px;
                    }
                    .price-text {
                        font-size: 45px;
                        padding-top: 10px;
                        display: inline-block;
                    }
                    .per {
                        display: block;
                        margin-top: 0px;
                        font-size: 16px;
                        font-weight: 400;
                        padding-left: 8px;
                    }

                    .iconContainer{
                        /*left: 80px;*/
                        position: relative;
                        top: -25px;
                        display: inline-block;
                        margin-left: -2px;
                        width: 20px;

                        .icon{
                            color: #c6c6c6 !important;

                            &:hover{
                                cursor: pointer;
                            }
                            font-size: 18px !important;
                        }

                    }

                    .tooltipbox{
                        display: inline-block;
                        position: absolute;
                        margin-left: 15px;
                        z-index: 6;
                        background-color: #fff;
                        width: 286px;
                        /*height: 100px;*/
                        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
                        padding: 10px;
                        font-weight: 400;

                    }
                }

                .plus {
                    display: inline-block;
                    font-size: 30px;
                    padding-left: 16px;
                    padding-right: 16px;
                    color: #0B61C9;
                    position: relative;
                    top: -10px;
                }

                .subtitle {
                    font-weight: 400;
                    font-size: 16px;
                    margin-top: 5px;
                    
                }

            }
              .included-price{
                    .price-text{
                        margin-top: 10px;
                    }
                    .subtitle {
                        margin-top: 2px;
                    }
                }
        }

        .md-button {
            height: 44px;
            padding-left: 15px;
            padding-right: 15px;
            box-shadow: none;
            color: #3671FF;
            border: 1px solid #3671FF;
            text-transform: none;
            margin-left: 0;
            top: 315px;
            position: absolute;
            right: 0;
            width: 130px;
        }

        .md-primary {
            color: #fff;
            border: none;
            background-color: #0078D3 !important;
        }
      
    }

      .md-layout-item.md-large-size-60{
            max-width: 740px;
            min-width: 740px;
        }

     @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {

    .md-layout-item.md-large-size-60{
        min-width:unset!important;
        width: 100%;
    }
    .headers h1{
        font-size: 25px;
        line-height: 34px;
        padding: 0px;
        margin: 0;
        letter-spacing: -1px;
    }

    .headers h2{
        font-size: 18px;
    }
    .md-app-content{
        padding: 15px;
        padding-top: 20px;
    }

    .choice-tabs .md-tab-nav-button .md-button-content{
        font-size: 16px;
    }
    .unlimited-leads{
        background: #D9EAF8;
        width: 100%;
        color: #2B4055;
        font-size: 18px;
        font-weight: 400;
        border-radius: 5px;
        height: 70px;
        margin-top: -1px;
        padding-top: 26px;
        padding-left: 20px;
    }
    h3{
        padding-top: 0;
        margin-top: 0;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .premium-header{
        margin-top: -1px;
        background: #D9EAF8;
        width: 100%;
        color: #2B4055;
        font-size: 18px;
        font-weight: 400;
        border-radius: 0px;
        height: 70px;
        padding-top: 26px;
        padding-left: 20px;
        text-decoration: none;
        border-radius: 5px;
    }
    .accordion-header a:hover{
         text-decoration: none;
    }
    .accordion-header a:visited{
         text-decoration: none;
    }

    .unlimited{
        .accordion-content{
            min-height: 270px;
        }
    }

    .accordion-content {
        min-height: 300px;
        ul{
            padding: 0;
            margin-top: 10px;
           
        }
        li{
            list-style-type:none;
            font-size: 16px;
            font-weight: 300;
            color: #545454;
        }
        .price-text{
            font-weight: 400;
            font-size: 18px;
            
        }
        hr{
            margin-bottom: 14px;
            border-top: 1px solid #eee;
            border-left: 0;
            height: 1px;
            overflow: hidden;
            padding:0;
        }
        .choose{
            letter-spacing: -0.5px;
        }
        .click-price, .click-leads {
            margin-top: 16px;
            letter-spacing: -1px;
            display: inline-block;
            color: #545454;
            .price-text{
                font-size: 36px;
                font-weight: 400;
            }
        }
        .call-price {
            display: inline-block;
            margin-left: 50px;
         }
        .subtitle{
            font-size: 16px;
            margin-top: 2px;
            font-weight: 300;
            margin-bottom: 0px;
            padding-bottom: 0px;
        }
         .iconContainer {
            position: relative;
            display: inline-block;
            .icon{
                font-size: 18px!important;
                color: #c6c6c6;
                margin-top: -54px;
                
            }
        }
    }
    .headers{
        margin-bottom: 24px;
    }

    .pricing{
        min-height: 120px;
    }
    .md-button {
        height: 44px;
        padding-left: 15px;
        padding-right: 15px;
        box-shadow: none;
        color: #fff;
        border: 1px solid #3671ff;
        text-transform: none;
        width: 130px;
        border: none;
        background-color: #0078d3!important;
        box-shadow: none!important;
        float: right;
        margin: 0;
        margin-bottom: 20px;
        margin-right: 0px;
    }

     .md-content{
        min-height: 99.9vh;
    }

    .click-price{
        position: relative;
        min-height: 60px;
    }

    .per{
        position: absolute;
        left: 18px;
        margin-top: 40px;
    }
    

}


</style>

<style lang="scss" scoped>

    /* RIBBON BANNERS */
    .ribbon {
        position: absolute;
        top: -17px;
        right: 1px;
        text-align: center;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .3s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }


    /* reset certain elements (in case of conflicting CSS for classes, links, etc.) */
    .ribbon .text,
    .ribbon .banner,
    .ribbon a {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        font-size: 100%;
        vertical-align: baseline;
        background: transparent;
    }

    .ribbon a {
        color: #fff;
        text-decoration: none;
    }

    .no-css-transforms .ribbon {
        font-size: 1em;
        position: relative;
        width: 100%;
    }

    .ribbon .banner {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        color: #fff;
        -webkit-font-smoothing: antialiased;
        display: block;
        float: right;
        position: relative;
        right: 0px;
        top: 22px;
        width: 100px;
    }

    .ribbon-small .banner {
        right: -8px;
        top: 33px;
        width: 65px;
    }

    .ribbon-large .banner {
        right: -20px;
        top: 32px;
        width: 150px;
    }

    .ribbon .banner::after,
    .ribbon .banner::before {
        content: '';
        display: block;
        height: 12px;
        position: absolute;
        width: 30px;
    }

    .ribbon-small .banner::after,
    .ribbon-small .banner::before {
        height: 6px;
        width: 20px;
    }

    .ribbon-large .banner::after,
    .ribbon-large .banner::before {
        height: 18px;
        width: 45px;
    }

    .ribbon .banner::before {
        -webkit-transform: skewY(-45deg) translate(50%,15px);
        -moz-transform: skewY(-45deg) translate(50%,15px);
        -ms-transform: skewY(-45deg) translate(50%,15px);
        -o-transform: skewY(-45deg) translate(50%,15px);
        -webkit-transform-origin: 100% center;
        -moz-transform-origin: 100% center;
        -ms-transform-origin: 100% center;
        -o-transform-origin: 100% center;
        left: -45px;
    }

    .ribbon-small .banner::before {
        top: -5px;
        left: -30px;
    }

    .ribbon-large .banner::before {
        top: 9px;
        left: -68px;
    }

    .ribbon .banner::after {
        -webkit-transform: translate(100%,-100%) skewY(45deg) translateX(-58%);
        -moz-transform: translate(100%,-100%) skewY(45deg) translateX(-58%);
        -ms-transform: translate(100%,-100%) skewY(45deg) translateX(-58%);
        -o-transform: translate(100%,-100%) skewY(45deg) translateX(-58%);
        -webkit-transform-origin: 0 center;
        -moz-transform-origin: 0 center;
        -ms-transform-origin: 0 center;
        -o-transform-origin: 0 center;
        right: -17px;
    }

    .ribbon-small .banner::after {
        top: 18px;
        right: -12px;
    }

    .ribbon-large .banner::after {
        top: 45px;
        right: -26px;
    }


    .no-css-transforms .ribbon .banner {
        position: static;
        width: 100%;
        float: none;
        font-size: 10px;
    }

    .ribbon .text {
        position: relative;
        z-index: 2;
        padding: 6px 0px;
        font-size: 14px;
        font-weight: bold;
        min-height: 18px;
        line-height: 18px;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.20);
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    /* small text */
    .ribbon-small .text {
        padding: 3px 0;
        font-size:12px;
        min-height: 14px;
        line-height: 14px;
    }

    /* large text */
    .ribbon-large .text {
        padding: 9px 0;
        font-size: 18px;
        min-height: 28px;
        line-height: 28px;
    }


    .ribbon .text::before,
    .ribbon .text::after {
        content: '';
        display: block;
        height: 30px;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    /* small text */
    .ribbon.ribbon-small .text::before,
    .ribbon.ribbon-small .text::after {
        height: 20px;
    }

    /* large text */
    .ribbon.ribbon-large .text::before,
    .ribbon.ribbon-large .text::after {
        height: 46px;
    }

    .ribbon .text::before {
        -webkit-transform: translateX(-15%) skewX(-45deg);
        -moz-transform: translateX(-15%) skewX(-45deg);
        -ms-transform: translateX(-15%) skewX(-45deg);
        -o-transform: translateX(-15%) skewX(-45deg);
    }

    .ribbon .text::after {
        -webkit-transform: translateX(15%) skewX(45deg);
        -moz-transform: translateX(15%) skewX(45deg);
        -ms-transform: translateX(15%) skewX(45deg);
        -o-transform: translateX(15%) skewX(45deg);
    }

    .no-css-transforms .ribbon .text {
        height: 25px;
        padding: 3px;
    }

    /* orange */
    .ribbon-orange .banner::after,
    .ribbon-orange .banner::before {
        background-color: #BE420C;
    }
    .ribbon-orange .text::before,
    .ribbon-orange .text::after,
    .no-css-transforms .ribbon-orange .text {
        background-color: #EC771B;
    }

    .backArrow{
   
        font-weight: 600;
        font-size: 20px;
        position: absolute;
        top: 360px;
        left: 120px;
        cursor: pointer;
        .md-icon {
            color: #0078D3;
        }
    }

    @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {
        .accordion-caret {
            background-image: linear-gradient(to top right, transparent 50%, #727272 50%);
            width: 0.5rem;
            height: 0.5rem;
            transform: rotate(-45deg);
        }

        .accordion{
            min-height: 600px;
        }

    
        $base-width: 320px;
        $base-time: 200ms;

        $accordion-background-color: #ffffff;
        $accordion-max-width: $base-width;
        $accordion-padding: 2rem 1rem;
        $accordion-border-radius: 0.5rem;
        $accordion-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);

        $accordion-header-font-size: 1.2rem;
        $accordion-header-height: 2rem;
        $accordion-header-padding: 1rem 1rem 1rem 1rem;
        $accordion-header-border-radius: 5px;
        $accordion-header-transition: background-color $base-time ease-in-out;

        $accordion-caret-width: 0.5rem;
        $accordion-caret-height: 0.5rem;
        $accordion-caret-transform: rotate(-45deg);
        $accordion-caret-transforming: scale(1.5) rotate(45deg);
        $accordion-caret-active-transform: rotate(135deg);

        $accordion-body-content-padding: 1rem;

        .accordion-item.accordion-active {
           
            .accordion-caret {
                animation: accordion-is-active $base-time linear forwards;
            }
        }
        .accordion-header {
            z-index: 100;
            position: relative;
        }
        .accordion-body{
            border-left: 2px solid #eee;
            border-right: 2px solid #eee;
            border-bottom: 2px solid #eee;
            border-top: 0;           
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            margin-bottom: 10px;
            margin-top: -1px;
            z-index: 0;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            margin-bottom: -1px;
            border: 0;
              position: relative;
    
        }
        .accordion-item{
            margin-top: 8px;
        }
        .accordion-header-div {
            &:last-child {
                padding-left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .accordion-caret {
            display: none;
        }

        .accordion-body {
            height: 0;
            overflow: hidden;
        }

        .accordion-content {
            padding: $accordion-body-content-padding;
        }
        .ribbon{
            top: -19px;
        }
        .ribbon-small .banner {
            right: -6px;
            width: 60px;
        }

        .accordion-content .tooltipbox{
            position: absolute;
            background: #fff;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
            z-index: 100;
            padding: 16px;
            padding-top: 10px;
            padding-bottom: 10px;
            width: 95%;
            left: 10px;
            font-size: 14px;
            letter-spacing: 0.1px;
            margin-top: -72px;
            .md-icon{
                font-size: 16px!important;
                position: absolute;
                top: 2px;
                right: 0;
                color: #c6c6c6;
            }
        }

    }
</style>